<template>
  <div>
    <div v-if="error">
      <ErrorView />
    </div>
    <router-view v-else class="router-view-container"></router-view>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import EventBus from '@/helpers/event-bus';
import ErrorView from '@/views/Error.vue';

export default {
  name: 'app',
  components: {
    ErrorView,
  },
  created() {
    EventBus.$on('logIssue', (account) => this.logContext(account));
  },
  computed: {
    error() {
      return this.$store.state.isError;
    },
  },
  methods: {
    logContext(account) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.setUser({ email: account?.email });
        Sentry.setContext('account', {
          name: account?.name,
          balance: account?.balance,
          invoices: account?.invoices?.length,
          customerId: account?.customerId,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '../src/assets/style.scss';
</style>

import Vue from 'vue';
import Axios from 'axios';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import EventBus from '@/helpers/event-bus';
import router from '@/router';
import store from '@/store';
import App from './App.vue';
import i18n from './i18n';
import '@/style/main.scss';
import '@/assets/css/tailwind.css';

Vue.config.productionTip = false;

// Configure Sentry error reporting
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://c1b0269298a341f791e2d2246b2018b4@o1329499.ingest.sentry.io/6591633',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'api.churnkey.co', 'api-dev.churnkey.co', /^\//],
      }),
    ],
    tracesSampleRate: 0.5,
  });
}

// return data directly
const formatResponse = (res) => res.data;

// eslint-disable-next-line func-names
const intercept = (err) => {
  // intercept errors and display error states
  const { response } = err;
  return new Promise(() => {
    if (response && response.status === 422) {
      // handle expired JWT
      EventBus.$emit('alert', 'Authentication Error', 'Please try logging in again');
      return;
    }
    if (response?.status === 401 || response?.status === 400) {
      // If jwt is bad, cache route to return after re-authenticating
      if (response.data === 'Session Expired' || response.data === 'jwt expired') {
        // TODO - we may not these auth interceptors. Remove if not.
        console.log(response.data);
      } else {
        // Capture and log other auth errors
        console.log(response.data);
      }
    }

    throw err;
  });
};

const token = localStorage.getItem('token');
if (token) {
  Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

// Axios configuration
Axios.interceptors.response.use(formatResponse, intercept);
Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || 'https://api.churnkey.co/v1/api';

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
